import React from "react"

import HeaderOfferings from "../../../components/header_offerings"

export default function SecondLook() {
  const heroTitle = "Second Look"

  return (
    <HeaderOfferings heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <p>
        Just like your physical health derives benefits from a periodic wellness check or a second
        opinion from a doctor, a second opinion for your current investment portfolio and investment
        plan could help you ensure you are on the right track in your financial journey and wealth
        creation.
      </p>

      <p>
        Our second opinion could assist you in identifying and even rectifying problems with your
        current strategy or simply provide an outside perspective to verify whether your current
        advisor or your do-it -yourself investment portfolio management strategy is aligned with
        your financial objectives and risk profile. For a nominal fee, it may also help you in
        understanding whether you are being “advised” or “(mis)sold financial products''.
      </p>

      <p>
        The objective of our in-depth “second look” analysis of your portfolio is to help you have
        the important confidence and peace of mind that comes from knowing a sound plan is in place
        and your investments remain on track towards achieving your personal financial goals.
      </p>
    </HeaderOfferings>
  )
}
